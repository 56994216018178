import { GetStaticProps, NextPage } from 'next';
import loadIntlMessages from '../utils/loadIntlMessages';
import { ErrorTemplate } from 'shared';
import { ErrorProps } from 'next/error';
import prefetchGlobals from '../utils/prefetchGlobals';
import { QueryClient } from '@tanstack/react-query';

const NotFoundPage: NextPage<ErrorProps> = props => <ErrorTemplate statusCode={404} {...props} />;

export default NotFoundPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    await prefetchGlobals(queryClient, locale);

    return {
        props: {
            intlMessages: await loadIntlMessages(locale),
        },
    };
};
